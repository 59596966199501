/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkout-address-table-border: var(--secondary-base-color);
    --checkout-address-table-hover-border: var(--color-red);
    --checkout-address-selected-color: var(--color-red);
    --checkout-address-table-check-mark-selected-color: var(--checkout-address-table-border);
}

.CheckoutAddressTable {
    width: 100%;

    &:first-child &-Button {
        margin-block-start: 0;
    }

    &-Buttons {
        @include after-mobile {
            display: flex;
            flex-direction: column-reverse;
            margin-block-start: 20px;
        }
    }

    &-Item {
        margin: 0 1rem 0 0;
        transition-property: background;
        transition-duration: 150ms;
        transition-timing-function: ease-in;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include after-mobile {
            min-height: 360px;
            height: 100%;
            padding: 15px 15px 17px;
        }

        @include isArabic {
            margin-inline-end: 0;
            margin-inline-start: 1.4rem;
        }

        &_isSelected {
            border: 2px solid var(--color-red);

            @include desktop {
                padding-block-end: 13px;
            }

            .LineBreak {
                display: none;
            }

            .CheckoutAddressTable-Buttons {
                @include desktop {
                    inset-block-end: 2px;
                }
            }

            &::before {
                transform: rotate(45deg);
                height: 15px;
                width: 5px;
                border-bottom: 2px solid var(--color-white);
                border-right: 2px solid var(--color-white);
                inset-inline-end: 9px;
                inset-block-start: 3px;
                position: absolute;
                content: '';
                z-index: 2;

                @include isArabic {
                    inset-inline-end: unset;
                    inset-inline-start: .9rem;
                }
            }

            &::after {
                background: #ff5501;
                color: var(--color-white);
                content: '';
                /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                font-family: 'icons-blank-theme';
                height: 27px;
                width: 29px;
                font-size: 27px;
                line-height: 21px;
                padding-block-start: 2px;
                position: absolute;
                inset-inline-end: 0;
                text-align: center;
                inset-block-start: 0;

                @include isArabic {
                    inset-inline-end: unset;
                    inset-inline-start: 0;
                }
            }

            --check-mark-opacity: 1;
            --checkout-address-table-border: var(--checkout-address-selected-color);
            --check-mark-color: var(--checkout-address-table-check-mark-selected-color);
        }
    }

    &-ItemDetails {
        div {
            margin: 10px;
            font-size: 14px;
            word-break: break-all;
        }
    }

    .Button-Checkout {
        width: 100%;
        font-weight: normal;

        @include after-mobile {
            width: fit-content;
        }
    }

    .LineBreak {
        width: 100%;
        height: 1px;
        color: var(--color-grey-c);
        margin-block-start: 12px;

        @include after-mobile {
            display: none;
        }
    }

    &-SelectedLabel {
        color: var(--checkout-address-selected-color);
        text-transform: uppercase;

        &::before {
            content: ' - ';
            color: $black;
        }
    }

    .KeyValueTable {
        margin: 0;
    }

    td,
    th {
        width: auto;
    }

    &-Phone {
        @include isArabic {
            direction: ltr;
            text-align: end;
        }
    }
}
