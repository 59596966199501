/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutNewAddressPopup {
    &-Address {
        margin: 1rem 0;
    }

    &_isVisible {
        height: 100%;
        inset-block-start: 0;
    }

    .Popup {
        &-Content {
            padding: 30px 10px 20px;
            overflow-y: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            min-width: 90%;
            max-height: unset;

            @include after-mobile {
                gap: 0;
                max-height: 550px;
                min-width: 800px;
                padding: 30px 45px 20px;
            }

            .CheckoutNewAddressForm {
                height: 90%;

                @include after-mobile {
                    height: 80%;
                }

                .FieldForm {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    max-height: unset;
                }

                &-FieldInputs {
                    max-height: 80%;
                }

                &-Actions {
                    margin-block-end: 0;
                }

                .Field {
                    &_type_checkbox {
                        .Field-Label {
                            inset-block-start: 5px;

                            @include after-mobile {
                                inset-block-start: 2px;
                            }
                        }

                        label {
                            text-transform: lowercase;

                            &::first-letter {
                                text-transform: uppercase;
                            }

                            &::after {
                                inset-inline-start: 5px;
                                inset-block-start: 8px;
                                width: 2px;

                                @include after-mobile {
                                    inset-inline-start: .5rem;
                                    inset-block-start: .75rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-Header {
            display: flex;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: calc(100% + 20px);
                height: 1px;
                background-color: var(--color-grey-c);
                inset-block-end: 0;
                inset-inline-end: -10px;

                @include after-mobile {
                    width: calc(100% + 90px);
                    inset-inline-end: -45px;
                }
            }

            h2 {
                font-size: 14px;

                @include after-mobile {
                    font-size: 26px;
                }
            }

            button {
                inset-block-start: 0;
                inset-inline-start: -10px;
                height: 23px;

                @include isArabic {
                    inset-inline-start: unset;
                    inset-inline-end: -10px;
                }

                &::before,
                &::after {
                    inset-block-start: 0;
                }
            }
        }
    }

    #telephone {
        @include isArabic {
            direction: ltr;
            text-align: end;
        }
    }
}
