/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.CheckoutOrderSummaryCartItem {
    margin: 0;
    list-style-type: none;
    padding: 0 0 20px;

    @include isArabic {
        padding-inline-start: .75rem;
    }

    &:not(:last-child) {
        border-bottom: 1px solid $c-gray-c;
    }

    &-ProductNameAndPrice {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-Picture {
        width: 200px;
        height: 200px;
    }

    &-Options {
        margin-block-end: 0;

        dl {
            margin: 14px 0;
        }

        dd {
            margin-inline-start: 40px;

            @include isArabic {
                margin-inline-start: 0;
                margin-inline-end: 40px;
            }
        }
    }

    &-Quantity {
        margin: 10px 0;

        @include isArabic {
            direction: ltr;
        }
    }

    &-ExpandableContentButton {
        display: flex;
        justify-content: flex-start;
        padding: 0;

        &::before {
            display: none;
        }

        &::after {
            content: url('../../../public/assets/images/icons/right-black-icon.svg');
            inset-inline-end: 0;
            background: transparent;
            width: 18px;
            height: 18px;
            position: relative;
            margin: 0 0 0 4px;

            @include isArabic {
                inset-inline-end: unset;
            }
        }

        &.ExpandableContent-Button_isContentExpanded {
            &::after {
                transform: rotate(270deg);
            }
        }
    }

    &-ExpandableContentHeading {
        font-size: 14px;
        font-weight: 300;
    }

    &-ExpandableContentContent {
        max-height: 0;
        overflow: hidden;
        margin: 0;
        opacity: 0;

        &_isContentExpanded {
            @include expanded-content;

            padding: 0;
        }
    }

    &::before {
        display: none;
    }
}
