/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutOrderSummary {
    padding: 22px 30px;
    margin: 20px 0;

    @include desktop {
        border: none;
    }

    &_isMobile {
        display: block;
        padding: 0;

        .CheckoutOrderSummary {
            &-SummaryItem {
                display: flex;
                flex-direction: column;

                &:first-child {
                    .CheckoutOrderSummary-PriceLinePrice {
                        padding-block-start: 0;
                    }
                }

                strong {
                    &.CheckoutOrderSummary-PriceLineName {
                        font-weight: normal;
                    }
                }
            }

            &-PriceLineName {
                font-weight: normal;
            }
        }

        @include desktop() {
            display: none;
        }
    }

    &-CartItemList {
        li {
            padding-inline-start: 0;
        }
    }

    &-Header {
        font-size: 26px;
        font-weight: 300;
        color: $c-gray-3;
        border-bottom: 1px solid $c-gray-c;
        padding-block-end: 10px;
        margin-block-end: 0;
    }

    &-OrderItems {
        margin-block-end: 0;

        @include mobile {
            background-color: unset;
        }
    }

    &-PriceLine {
        &Name {
            padding: 4px 10px 10px 4px;

            @include isArabic {
                padding-inline-end: 4px;
                padding-inline-start: 10px;
            }
        }

        &Price {
            padding: 4px 4px 10px;
        }
    }

    &-SummaryItem {
        padding: 0;

        &:first-child {
            .CheckoutOrderSummary-PriceLineName,
            .CheckoutOrderSummary-PriceLinePrice {
                padding-block-start: 20px;
                padding-block-end: 10px;
            }
        }

        &:last-child {
            * {
                font-weight: bold;
            }
        }
    }

    &-ExpandableContentButton {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 10px 40px 10px 0;
        border-bottom: 1px solid $c-gray-c;

        @include isArabic {
            padding-inline-start: 0;
        }

        &::before {
            display: none;
        }

        &::after {
            content: url('../../../public/assets/images/icons/right-black-icon.svg');
            inset-inline-end: 0;
            background: transparent;
            width: 18px;
            height: 18px;
        }

        &.ExpandableContent-Button_isContentExpanded {
            &::after {
                transform: rotate(270deg);
            }
        }
    }

    &-ExpandableContentHeading {
        font-size: 18px;
        font-weight: 300;

        @include isArabic {
            direction: ltr;
        }
    }

    &-ExpandableContentContent {
        max-height: 0;
        overflow: hidden;
        margin: 0;
        opacity: 0;

        &_isContentExpanded {
            @include expanded-content;

            overflow: scroll;
            padding-block-end: 25px;

            @include smaller-desktop {
                overflow-x: auto;
            }

            margin: 0 -15px 0 0;
            max-height: 370px;
            padding: 15px 15px 0 0;
        }
    }

    &-Discount {
        display: flex;
        justify-content: space-between;
        height: 50px;
        margin-block-start: 0;
        padding: 7px 0;
        position: relative;
        width: 100%;
        font-family: var(--font-FFDIN);

        @include isArabic {
            font-family: var(--font-DINArabic);
        }

        @include desktop {
            padding: 7px 15px 7px 0;
        }

        &Button {
            align-items: center;
            border-top: 1px solid $c-gray-c;
            inset-block-end: 0;
            display: flex;
            height: 100%;
            justify-content: flex-end;
            position: absolute;
            inset-inline-end: 0;
            width: 100%;

            &::after {
                background: transparent;
                content: url('../../../public/assets/images/icons/right-black-icon.svg');
                height: 18px;
                inset-inline-end: 0;
                transform: rotate(90deg);
                width: 18px;

                @include isArabic {
                    inset-inline-start: 0;
                    inset-inline-end: unset;
                }
            }

            &_isDiscountExpanded {
                &::after {
                    transform: rotate(270deg);
                }
            }

            span {
                display: none;
            }
        }

        span {
            font-size: 18px;
            height: max-content;
            inset-block-start: 9px;
        }

        .CheckoutOrderSummary-DiscountButton {
            border-top: none;
            border-bottom: 1px solid $c-gray-c;
        }
    }

    &-DiscountCode {
        overflow: hidden;

        @include desktop {
            inset-block-start: -10px;
        }

        .CartCoupon {
            height: 0;
            padding: 0;

            @include desktop {
                margin-inline-start: 4px;
                padding: 0;
            }

            &-Button {
                margin: 0 0 22px 0;

                &_Remove {
                    margin: 25px 0 22px;
                }
            }

            .Field {
                padding-block-start: 12px;

                @include desktop {
                    padding-block-start: 8px;
                }
            }
        }

        &_isDiscountExpanded {
            .CartCoupon {
                height: auto;

                &-Message {
                    margin-block-start: 25px;
                }

                &-Input {
                    .FieldInput-Wrapper {
                        margin-block-start: 25px;
                    }
                }
            }
        }
    }

    .ProductPrice {
        font-size: 14px;

        @include mobile {
            font-size: 18px;
        }
    }
}
