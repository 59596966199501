/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/util';
@import '../../../../../../src/style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

@mixin checkout-mobile {
    @media screen and (max-width: 500px) {
        @content;
    }
}

.CheckoutComCardPayment-Wrapper {
    margin-top: 20px;

    iframe {
        width: 100%;
    }

    .one-liner {
        display: flex;
        flex-direction: column;

        @include after-mobile {
            flex-direction: row;
        }
    }

    .CheckoutComCardPayment-CardFrame {
        border-bottom: 1px solid var(--input-border-color);
        height: 40px;
        margin-bottom: 10px;
        visibility: hidden;
        width: 100%;

        @include after-mobile {
            margin-bottom: 0;
        }

        &.frame--activated {
            visibility: visible;
        }

        &.frame--rendered {
            opacity: 1;
        }

        &.frame--rendered.frame--focus {
            border: solid 1px #13395e;
            box-shadow: 0 2px 5px 0 rgba(19, 57, 94, .15);
        }

        &.frame--rendered.frame--invalid {
            border: solid 1px #d96830;
            box-shadow: 0 2px 5px 0 rgba(217, 104, 48, .15);
        }
    }

    .CheckoutComCardPayment-ErrorMessage {
        color: #c9501c;
        font-size: 1.2rem;
        font-weight: 300;
        margin: 8px 0 0 1px;
    }

    .success-payment-message {
        color: #13395e;
        line-height: 1.4;
    }

    .token {
        color: #b35e14;
        font-size: .9rem;
    }

    .CheckoutComCardPayment-Frames {
        max-width: 700px;
        display: flex;
        height: 50px;

        @include desktop {
            justify-content: space-between;
        }

        @include checkout-mobile {
            display: block;
            min-width: 100px;
            max-height: 90px;
        }

        .card-frame {
            height: 50px;
            width: 100%;

            #singleIframe {
                border: solid 1px var(--input-color) !important;
            }
        }

        .CardTopInputs {
            display: flex;

            @include checkout-mobile {
                max-height: 40px;
            }
        }

        .CardBottomInputs {
            display: flex;
            max-width: 230px;

            @include checkout-mobile {
                margin-left: 45px;

                @include isArabic {
                    margin-left: 0;
                    margin-right: 47px;
                }
            }

            .expiry-date-frame {
                margin-right: 50px;
                margin-left: 50px;

                @include checkout-mobile {
                    margin-left: 0;
                    max-height: 40px;

                    @include isArabic {
                        margin-right: 0;
                        margin-left: 50px;
                    }
                }
            }
        }

        .card-icon {
            background-image: url('./icons/card.svg');
            background-repeat: no-repeat;
            background-size: 25px;
            min-width: 26px;
            height: 30px;
            top: 12px;
            margin-right: 20px;

            @include isArabic {
                margin-right: 0;
                margin-left: 20px;
            }
        }

        .card-number-field {
            width: 60%;
        }

        #cardNumber, #expiryDate, #cvv {
            border: solid 1px var(--input-color) !important;
            padding-left: 5px !important;
            height: 50px !important;

            @include isArabic {
                padding-left: unset !important;
                padding-right: 5px !important;
            }
        }

        #expiryDate, #cvv {
            @include checkout-mobile {
                margin-top: 20px !important;
            }
        }

        #cvv {
            min-width: 75px !important;
        }

        #expiryDate {
            min-width: 75px;
        }
    }
}
