/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutGuestForm {
    margin-block-start: 0;

    .Field_type_checkbox {
        label:last-of-type {
            margin-inline-start: 0;
        }

        label:first-of-type {
            font-size: var(--font-size-small);
            display: flex;
            flex-direction: unset;
            justify-content: center;


            @include desktop {
                min-width: calc(100% - 40px);
                line-height: 2rem;
                font-size: 1.05rem;
            }
        }
    }

    .Field {
        max-width: 100%;

        @include desktop {
            max-width: 87%;
        }
    }

    .Field-Label {
        @include tablet {
            @include isArabic {
                margin-inline-end: 1rem;
            }
        }
    }

    .FieldInput-Wrapper {
        display: flex;

        >div {
            width: 100%;
        }

        .FieldInput-QuestionIconWrapper {
            width: auto;
        }

        .Icon-CheckGreen {
            position: absolute;
            inset-block-start: 15px;
            inset-inline-end: 1rem;
            width: 13px;
            height: 12px;

            @include isArabic {
                inset-inline-end: unset;
                inset-inline-start: 1rem;
            }

            @include desktop {
                inset-inline-end: -3rem;

                @include isArabic {
                    inset-inline-end: unset;
                    inset-inline-start: -3rem;
                }
            }
        }
    }

    .FieldInput-QuestionIconText {
        @include isArabic {
            inset-inline-end: unset;
            inset-inline-start: 0;
        }
    }
}
