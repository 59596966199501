/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutAddressForm {
    max-width: 100%;

    .Field {
        max-width: 100%;

        @include desktop {
            max-width: 87%;
        }
    }

    .Field_type_phone {
        .FieldInput-InputWrapper {
            width: 100%;

            input {
                font-size: var(--font-size-medium);
            }
        }
    }

    .Field_type_select {
        margin-block-end: 25px;
    }

    .FieldInput {
        &-Label_isFocused {
            inset-block-start: 25%;

            /**
            for overriding default font size on desktop standart mixins won`t help as default font size setted on
            input.scss still overriding this one
            **/
            /* stylelint-disable-next-line declaration-no-important */
            font-size: var(--font-size-nano) !important;
        }
    }

    .FieldSelect-LabelPlaceholder {
        font-size: var(--font-size-nano);
    }

    &:last-child {
        #country_id,
        #country_id_billing {
            .CheckoutAddressBook-AE & {
                display: none;
            }
        }
    }

    #postcode {
        @include isArabic {
            direction: rtl;
        }

        &:focus {
            text-align: start;
        }
    }

    .CountryFieldUAE {
        display: none;
    }
}
