/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutSuccess {
    h3 {
        @include isArabic {
            direction: ltr;
            text-align: start;

            @include mobile {
                text-align: center;
            }
        }
    }

    &-Buttons {
        display: flex;
        margin-block-start: 25px;
        justify-content: center;
        position: fixed;
        inset-block-end: 0;
        flex-direction: column;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        width: 100%;
        z-index: 20;
        background: var(--color-white);

        @include isArabic {
            inset-inline-start: unset;
            inset-inline-end: 50%;
        }

        @include desktop {
            justify-content: unset;
            flex-direction: row;
            position: relative;
            inset-block-end: unset;
            background: unset;
        }

        .Button-Checkout {
            min-width: 190px;
            text-align: center;
        }

        .CheckoutSuccess-ButtonWrapper {
            &_isCreateAccountButton {
                a {
                    @include desktop {
                        @include isArabic {
                            margin-inline-end: 2.5rem;
                        }
                    }
                }
            }
        }
    }

    &-ButtonWrapper {
        z-index: 1;
        border-top: none;
        width: unset;
        inset-block-end: 0;

        @include mobile {
            position: relative;
        }

        &_isCreateAccountButton {
            @include desktop {
                margin-inline-start: 30px;
            }

            @include mobile {
                position: relative;
            }
        }

        &:nth-of-type(1) {
            inset-inline-end: 0;
        }
    }

    &-ShippingAddress {
        width: fit-content;
        margin: 0 auto;
        text-align: start;

        @include desktop {
            margin: 0;
            padding-block-start: 23px;
        }

        .KeyValueTable {
            &-Wrapper {
                /* stylelint-disable-next-line declaration-no-important */
                max-width: 100% !important;
            }
        }
    }
}
