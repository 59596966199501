/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/util';
@import '../../../../../../src/style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutComGooglePay {
    &-GooglePayButton {
        background-origin: content-box;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
        outline: 0;
        padding: 11px 24px;
        width: 100%;
        min-width: 90px;
        height: 40px;
        min-height: 40px;
        margin-top: 5px;
        margin-bottom: 5px;

        &:hover {
            @include desktop {
                cursor: pointer;
            }
        }

        &_type_white {
            background-color: #fff;
            background-image: url("./images/whiteLogo.svg");

            &:hover {
                background-color: #F2F2F2;
            }
        }

        &_type_black {
            background-color: #000;
            background-image: url("./images/darkLogo.svg");
            box-shadow: unset;

            &:hover {
                background-color: #3c4043;
            }
        }
    }
}
