/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

.CheckoutPayment {
    display: flex;
    margin: .5rem -1rem;
    border-top: none;
    padding: 0;

    @include desktop {
        margin-inline-start: 0;
        margin-inline-end: 0;
    }

    &:first-of-type {
        border-top: none;
    }

    &:last-of-type {
        border-top: none;
        border-bottom: none;
    }

    &-Button {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 3rem 1rem;
        text-align: start;

        @include mobile {
            font-size: 14px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
            border: 1px solid var(--color-grey-d5);
            border-radius: 100%;
            opacity: 1;
            inset-block: 0;
            margin: auto;
            z-index: 1;
            inset-block-start: -5px;
            inset-inline-start: 11px;

            @include desktop {
                inset-inline-start: 0;
            }
        }

        &::before {
            content: '';
            position: absolute;
            width: 11px;
            height: 11px;
            border-radius: 100%;
            opacity: 0;
            background: var(--color-red);
            border: 1px solid var(--color-red);
            inset-inline-start: 18px;
            inset-block-start: 15px;

            @include desktop {
                inset-inline-start: 7px;
                inset-block-start: 13px;
            }
        }

        &_hasImage {
            padding: 0 3rem 0;

            @include mobile {
                padding: 0.15rem 1rem 0.15rem 3rem;

                @include isArabic {
                    padding: 0.15rem 3rem 0.15rem 1rem;
                }
            }

            &::before {
                @include isArabic {
                    inset-block-start: 15px;

                    @include desktop {
                        inset-block-start: 13px;
                    }
                }
            }
        }

        &:hover {
            &::before {
                will-change: opacity;
                transition: opacity .3s;
                opacity: .7;
            }
        }

        &_isSelected {
            &::before {
                opacity: 1;
            }
        }
    }

    &-Cod {
        font-weight: bold;
        padding-block-start: 20px;
        text-transform: uppercase;
    }

    &-Image {
        height: 45px;
        object-fit: contain;
        object-position: center center;
        width: 8rem;

        @include desktop {
            width: 10rem;
        }
    }
}
