/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

.Checkout {
    padding-block-end: 50px;
    margin-block-start: 0;

    &::before {
        display: none;
    }

    > section {
        height: 100%;
    }

    &_isSuccess {
        display: flex;
        justify-content: center;
        align-items: center;

        @include desktop {
            height: calc(100vh - 100px);
        }

        .Checkout {
            &-Wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-around;
                padding: 0;
                padding-block-start: 60px;

                @include desktop {
                    max-width: unset;
                    padding-block-start: 81px;
                }
            }
        }

        >section {
            width: 100%;

            #checkout1 {
                @include desktop {
                    position: absolute;
                    width: 120%;
                    margin-inline-start: -10%;
                }
            }
        }
    }

    &-MainLogo {
        height: 60px;
        width: 92px;
        padding: 17px 24px 0 26px;

        @include desktop {
            height: 81px;
            padding: 26px 13px 25px 40px;

            @include isArabic {
                padding: 26px 40px 25px 13px;
            }
        }

        &Wrapper {
            max-width: var(--header-nav-max-width);
            margin: 0 auto;
        }

        &Link {
            display: block;
            width: fit-content;
        }
    }

    &-Step {
        grid-column: 1;

        .Checkout & {
            max-width: unset;
        }
    }

    &-Heading {
        .Checkout & {
            font-size: 16px;

            @include desktop {
                font-size: 26px;
                padding-block-end: 10px;
                border-bottom: 1px solid #{$c-gray-c};
                font-weight: 300;
                margin-block-start: 0;
            }
        }
    }

    .Checkout-Wrapper {
        padding-block-start: 60px;

        @include desktop {
            grid-template-columns: 2fr 1fr;
            grid-column-gap: 2%;
            grid-row-gap: 0px;
            max-width: var(--checkout-max-width);
            padding: 0 1rem;
            padding-block-start: 81px;
        }
    }

    +.CmsBlock-Wrapper .Footer {
        display: none;
    }

    &-Navigation {
        margin-block-end: 20px;
        counter-reset: item;
        list-style-type: none;
        display: flex;
        align-items: flex-start;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        li {
            padding-inline-start: 0;
        }

        &Step {
            /* stylelint-disable-next-line declaration-no-important */
            margin: 0px !important; // The _reset.scss style takes higher precedence, don't know how to fix.
            padding: 0px;
            display: grid;
            align-items: center;

            @include mobile {
                width: 50%;
            }

            &:first-child {
                &::after {
                    border-radius: 6px 0 0 6px;
                }
            }

            &:last-child {
                &::after {
                    border-radius: 0 6px 6px 0;
                }
            }

            &::before {
                display: none;
            }

            &::after {
                content: '';
                width: 185px;
                height: 7px;
                background-color: $c-gray-e4;
                display: block;
                margin: calc(31px / 2) 0px;

                @include mobile {
                    width: 100%;
                }
            }

            &_isCurrent {
                &::after {
                    background-color: $c-red;
                }
            }

            &Index {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 38px;
                height: 38px;
                border: 6px solid $c-gray-e4;
                border-radius: 100%;
                background-color: $white;
                position: absolute;
                inset-block-start: 0px;
                grid-row: 1;
                inset-inline-start: calc(50% - 19px);

                &::before {
                    content: counter(item);
                    counter-increment: item;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1;
                    font-family: Arial, sans-serif; // Fixes centering issues on safari, so that it is PERFECTLY centered...
                    width: 1ch;
                }

                &_isCurrent {
                    border-color: $c-red;

                    &::before {
                        width: 16px;
                        height: 16px;
                        content: url('../../../public/assets/images/icons/checkout-navigation-checkmark.svg');
                    }
                }
            }

            &Label {
                color: $c-gray-e4;
                font-weight: 300;
                font-size: 18px;
                grid-row: 2;
                padding-block-start: 7px;
                text-align: center;

                &_isCurrent {
                    color: $c-gray-3;
                }
            }
        }
    }

    &-BottomFiller {
        @media (min-height: 1000px) {
            // 68px is the height without bottom padding of the navigation element
            height: calc(68px * 2);
        }
    }

    &-StickyButtonWrapper {
        inset-inline-start: 0;
        inset-block-end: 0;

        @include before-desktop {
            padding: 1rem 1rem calc(env(safe-area-inset-bottom) + 1rem);
        }
    }

    .Loader {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 100;
    }
}

.CheckoutShipping {
    .FieldInput-QuestionIconText {
        @include isArabic {
            inset-inline-end: unset;
            inset-inline-start: 0;
        }
    }
}
