/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutNewAddressForm {
    margin-block-start: -14px;
    height: 65%;

    @include mobile {
        width: 90%;
        margin: 0 auto;
        padding-block-start: 1rem;
    }

    .FieldForm {
        height: 100%;
    }

    &-FieldInputs {
        height: 100%;
        overflow-y: auto;
        border-bottom: 1px solid var(--color-grey-c);
        padding-block-end: 10px;

        @include mobile {
            border-bottom: none;
        }

        .Field {
            margin-block-start: 0;

            .FieldInput-Wrapper {
                width: 100%;
            }

            input {
                width: 100%;
            }

            &_type_checkbox {
                margin-block-start: 30px;

                label {
                    @include isArabic {
                        /* stylelint-disable-next-line declaration-no-important */
                        margin-inline-start: 0!important;
                    }
                }
            }

            &_type_phone {
                .FieldInput-InputWrapper {
                    width: 100%;
                }

                .FieldInput-Message {
                    position: absolute;
                    inset-block-start: 3.8rem;
                    color: var(--color-red);

                    @include mobile {
                        inset-block-start: 3.5rem;
                    }
                }
            }

            label:first-of-type ~ label {
                margin-inline-end: 0;
                margin-inline-start: 0;
                width: 22px;
                height: 22px;
            }

            &_type_zip {
                .Field-Message {
                    position: absolute;
                    inset-block-start: 3.8rem;
                    color: var(--color-red);
                    font-weight: normal;

                    @include mobile {
                        inset-block-start: 3.5rem;
                    }
                }
            }
        }

        .Icon {
            &-CheckGreen {
                display: none;
            }
        }
    }

    @include after-mobile {
        margin-block-start: 2rem;
    }

    &-Actions {
        display: flex;
        justify-content: flex-end;
        margin-block-end: 80px;
        border-style: solid;
        padding-block-start: 0;

        @include desktop {
            padding-block-start: 4rem;
        }

        .Button-Checkout {
            &_withArrow:last-of-type {
                margin-inline-start: 20px;
            }
        }

        .MobileAddressPopupSaveButton {
            width: 100%;
            min-height: 50px;
        }
    }

    #telephone {
        direction: ltr;
    }

    #postcode {
        @include isArabic {
            direction: rtl;
            text-align: start;
        }

        &:focus {
            @include isArabic {
                text-align: start;
            }
        }
    }

    .FieldSelect {
        margin-block-end: 2rem;
    }

    label[for="street"],
    label[for="street2"] {
        text-transform: initial;
    }

    &-CountryField {
        display: none;
    }
}
