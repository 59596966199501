/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/util';
@import '../../../../../../src/style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 * @author Deniss Dubinins <denissd@scandiweb.com | info@scandiweb.com>
 */

.CheckoutComApplePayPayment-Wrapper {
    margin-top: 20px;

    .AddToCartPopup & {
        margin-left: 23px;
        margin-right: 23px;
    }

    .CheckoutComApplePayPayment-Button {
        direction: ltr;
        display: inline-block;
        height: 50px !important;
        width: 100% !important;
        background-color: #000;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .AddToCartPopup & {
            @supports (-webkit-appearance: -apple-pay-button) {
                height: 45px !important;
            }
        }

        &:disabled {
            opacity: .5;
        }

        .ApplePayIcon {
            background-image: url("./icons/apple-logo.png");
            background-repeat: no-repeat;
            background-size: 31px 31px;
            height: 34px;
            width: 26px;
        }

        .ApplePayText {
            color: #fff;
            font-size: 26px;
        }
    }

    .CartOverlay & {
        width: 100%;
    }
}
