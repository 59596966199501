/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.CheckoutAddressBook {
    &-Wrapper {
        @include after-mobile {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @include small-desktop {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .FieldInput-QuestionIconText {
        &::after {
            inset-inline-end: 0;
        }
    }

    #telephone {
        direction: ltr;
    }

    .FieldForm-Fields {
        display: unset;
        grid-template-columns: unset;
        grid-column-gap: unset;
    }
}
