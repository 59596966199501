/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.CheckoutDeliveryOption {
    border: none;
    padding: 0;
    padding-inline-start: 0; // yes both padding and padding-inline-start are needed.

    &:hover {
        .CheckoutDeliveryOption {
            &-Row * {
                color: var(--color-black);
            }
        }
    }

    &:last-child {
        border: none;
    }

    &-Row {
        display: flex;
        justify-content: space-between;
        flex: 1;
        font-size: 12px;
        inset-block-start: 3px;

        @include after-mobile {
            font-size: 14px;
        }

        & > span,
        & > strong {
            flex-grow: 1;
            flex-basis: 0;
        }
    }

    &-FreeShippingPrice {
        flex-grow: 2;
        flex-basis: 0;
        display: flex;
        gap: 10px;

        .NewPrice {
            color: var(--color-red)
        }

        .OldPrice {
            &::before {
                position: absolute;
                transform: translateY(-50%);
                inset-block-start: 50%;
                content: '';
                border-bottom: solid 2px var(--color-red);
                backface-visibility: hidden;
                height: 0;
                width: 100%;
            }
        }
    }

    .DeliveryOptionsButton {
        width: 24px;
        height: 24px;
        border: 1px solid var(--color-grey-d5);
        border-radius: 100%;
        margin-inline-end: 15px;

        &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            inset-inline-start: 5px;
            inset-block-start: 5px;
        }
    }

    &-Button {
        display: flex;
        padding: 1rem 0;
        align-items: center;

        &::after {
            content: none;
            display: none;
        }

        &_isSelected {
            .DeliveryOptionsButton {
                &::after {
                    background: var(--color-red);
                    border: 1px solid var(--color-red);
                }
            }
        }
    }

    strong {
        @include isArabic {
            display: flex;
            align-items: center;
        }
    }

    span {
        @include isArabic {
            display: flex;
            align-items: center;
        }
    }
}
