/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CheckoutBilling {
    &-Checkbox {
        display: flex;
        margin-block-end: 2rem;
        align-items: center;

        @include tablet {
            @include isArabic {
                .Field-Label {
                    margin-inline-end: 1rem;
                }
            }
        }
    }

    &-TermsAndConditions {
        display: flex;
        margin-block-end: calc(env(safe-area-inset-bottom) + 2rem);
        flex-direction: row;
        font-size: $fs-medium-small;

        label {
            @include isArabic {
                margin-inline-start: 1rem;
            }

            @include isNotArabic {
                margin-inline-end: 1rem;
            }
        }

        a + a {
            margin-inline-start: 0;
        }
    }

    &-SubscriptionContainer {
        display: flex;
        flex-direction: row-reverse;

        @include mobile {
            margin-block-end: 1rem;
            padding-block-start: 1rem;
        }

        >label {
            margin-inline-start: 1rem;
            font-size: 1.05rem;

            a {
                font-size: inherit;
            }
        }

        .Field-CheckboxLabel {
            .input-control {
                margin-inline-end: 0
            }
        }
    }
}
