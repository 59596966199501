/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/util';
@import '../../../../../../src/style/abstract/menu-scrollbar';

#adyen-checkout-adyen_applepay,
#adyen-checkout-adyen_applepay-express {
    min-width: 240px;
    width: 100%;

    button {
        -webkit-appearance: none;
        background: #000;
        border-radius: 0.5rem;
        background-image: url("./icons/apple-checkout.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
    }

    .AddToCartPopup & {
        margin: 0 23px;
        width: auto;
    }
}

#adyen-checkout-adyen_applepay-express {
    text-align: center;
    padding-block-start: 6px;
}

.CheckoutPayments {
    [id^=adyen-checkout-] {
        margin-block-start: 10px;
    }

    #adyen-checkout-adyen_googlepay {
        width: 100%;

        button {
            width: 100%;
        }
    }
}

#adyen-checkout-card {
    .adyen-checkout__label__text {
        font-size: 14px;

        @include isArabic {
            text-align: end;
        }
    }
}
